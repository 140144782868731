@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.animate-fadeIn {
    animation: fadeIn 0.5s ease-in-out;
}

.animate-fadeOut {
    animation: fadeOut 0.5s ease-in-out;
}

.light {
    --info-background: #dbeafe;
    --info-border: #3b82f6;
    --info-text: #1d4ed8;

    --warning-background: #fef9c3;
    --warning-border: #eab308;
    --warning-text: #a16207;

    --error-background: #fee2e2;
    --error-border: #ef4444;
    --error-text: #b91c1c;

    --success-background: #dcfce7;
    --success-border: #22c55e;
    --success-text: #15803d;

    --setting-section-title: #9ca3af;
    --service-account-text: #6b7280;
    --service-account-warning-text: #dc2626;

    --button: #2563eb;
    --disabled-button: #d1d5db;

    --sidebar-background: #f9fafb;
    --sidebar-divider: #a1a1aa;
    --sidebar-button-text: #6b7280;
    --sidebar-button-hover: #f3f4f6;

    --project-card-border: #d1d5db;
    --new-project-card-background: #f3f4f6;

    --profile-border: #2563eb;

    --page-title: #3f3f46;

    --dropzone-background: #f4f4f5;
    --dropzone-background-hover: #e4e4e7;

    --accordion-background: #f4f4f5;
    --accordion-item-hover: #e4e4e7;
    --accordion-text: #a1a1aa;

    --disabled-input-background: #f4f4f5;

    --percentage-text-positive: #15803d;
    --percentage-text-negative: #991b1b;

    --chart-axis-color: #000000;
    --chart-legend-color: #000000;

    --search-results-background: #f4f4f5;
    --search-results-highlighted: #e4e4e7;
    --search-results-title: #000000;

    --input-background: #d1d5db;
}

.dark {
    --info-background: #dbeafe;
    --info-border: #3b82f6;
    --info-text: #1d4ed8;

    --warning-background: #fef9c3;
    --warning-border: #eab308;
    --warning-text: #a16207;

    --error-background: #fee2e2;
    --error-border: #ef4444;
    --error-text: #b91c1c;

    --success-background: #dcfce7;
    --success-border: #22c55e;
    --success-text: #15803d;

    --setting-section-title: #9ca3af;
    --service-account-text: #6b7280;
    --service-account-warning-text: #dc2626;

    --button: #4b5563;
    --disabled-button: #27272a;

    --sidebar-background: #18181b;
    --sidebar-divider: #52525b;
    --sidebar-button-text: #d1d5db;
    --sidebar-button-hover: #27272a;

    --project-card-border: #d1d5db;
    --new-project-card-background: #3f3f46;

    --profile-border: #2563eb;

    --page-title: #d4d4d8;

    --dropzone-background: #27272a;
    --dropzone-background-hover: #52525b;

    --accordion-background: #18181b;
    --accordion-item-hover: #3f3f46;
    --accordion-text: #a1a1aa;

    --disabled-input-background: #3f3f46;

    --percentage-text-positive: #86efac;
    --percentage-text-negative: #fca5a5;

    --chart-axis-color: #ffffff;
    --chart-legend-color: #ffffff;

    --search-results-background: #27272a;
    --search-results-highlighted: #52525b;
    --search-results-title: #ffffff;

    --input-background: #52525b;

    background: #27272a
}